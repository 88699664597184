<template>
    <div class="terms-of-use-content">

        <span class="terms-of-use-heading">
                    Terms of Use
        </span>
        <span class="terms-of-use-sub-heading">
                    The Core Statement
        </span>
        <p>
            We would like to ask you to behave responsibly and not share or create content that violates our terms
            of
            use. We do not tolerate content such as spam, viruses, hate content, or material that violates applicable
            laws.
            If you discover content or a space that violates our Terms of Service, please contact us at
            contact@wtfai.net.
            We hope you will enjoy WTFAI and have fun with your friends.
        </p>

        <span class="terms-of-use-sub-heading">
        Terms and Conditions:
        </span>
        <p>
            The following terms and conditions govern your use of the entire "Who the f*ck am I?" website and all
            content,
            services and products made available on or through the website. The Website is offered subject to your full
            acceptance of all of the terms and conditions contained herein and all other applicable operating rules and
            policies (including, without limitation, our Privacy Policy. Please read this Agreement carefully before
            using
            or accessing the Website. By using or accessing any part of the Site, you agree to be bound by the terms and
            conditions of this Agreement. If you do not agree to all of the terms and conditions in this Agreement, then
            you
            may not access this Site. If you create a space on the Site, you are responsible for maintaining the
            security of
            your space. You are also responsible for all activities that occur in the room and any other actions taken
            in
            connection with the room. You must notify WTFAI immediately of any unauthorized use of your Space or any
            other
            breach of security. WTFAI shall not be liable for any acts or omissions by you, including damages of any
            kind
            incurred as a result of such acts or omissions.
        </p>


        <span class="terms-of-use-sub-heading">
        Definitions
        </span>
        <p>
            In these Terms and Conditions, "you" refers to both individuals and entities who access or use our Website.
            If
            you are an individual using WTFAI on behalf of a legal entity, you represent and warrant that you have the
            authority to bind that legal entity to these Terms and Conditions and that you agree to the Terms and
            Conditions
            by using WTFAI on behalf of that legal entity.
        </p>


        <span class="terms-of-use-sub-heading">
        Minimum Age
        </span>
        <p>
            Our Services are not directed to children. Access to and use of our Website is intended only for persons
            over
            the age of 16 in the European Union. If you are younger, you may not use our Website. Any person using our
            website , confirms that they are at least 16 years old.
        </p>


        <span class="terms-of-use-sub-heading">
            Obligations of contributors/website visitors
        </span>
        <p>
            If you operate a game, or participate in a game, post material or links in a game, or otherwise make
            available
            (or authorize a third party to make available) material through the Site (any material as "Content"), you
            are
            fully responsible for its Content and any harm resulting from that Content. This applies regardless of the
            form
            in which the Content is presented, including but not limited to text, photo, video, audio or code. By
            providing
            Content, you represent and warrant that your Content does not violate these Terms and Conditions.
        </p>

        <p>
            If you distribute prohibited content, WTFAI will use reasonable efforts to remove it from the Website. You
            acknowledge, however, that caching or references to the content may not be promptly removed. Subject to any
            representations or warranties, WTFAI has the right (but not the obligation) in WTFAI's sole discretion to
            refuse or remove any content that, in WTFAI's sole discretion, violates any WTFAI policy or is in any way
            infringing or objectionable, or to terminate or deny access to and use of the Website to any individual or
            entity for any reason, in WTFAI's sole discretion. WTFAI has not reviewed, and cannot review, all of the
            material, including computer software, posted to the Website, and cannot therefore be responsible for that
            material's content, use or effects. By operating the Website, WTFAI does not represent or imply that it
            endorses the material there posted, or that any such material is accurate, useful or non-harmful. You are
            responsible for taking necessary security precautions to protect yourself and your computer systems from
            viruses, worms, Trojan horses and other harmful and destructive content. The Website may contain content
            that is
            offensive, indecent or otherwise objectionable, as well as content that contains technical inaccuracies,
            typographical errors and other mistakes. The Site may also contain material that violates proprietary or
            publicity rights or infringes the intellectual property or other proprietary rights of others. Downloading,
            copying or use of the material may also be subject to additional stated or unstated terms and conditions.
            WTFAI disclaims any responsibility for any harm resulting from the use by users of the Website, or from any
            downloading by such users of content there posted.
        </p>


        <span class="terms-of-use-sub-heading">
            General Representation and Warranty
        </span>
        <p>
            You represent and warrant that your use of our Services will be in accordance with the following:
        </p>


        <p>
            It will be done in strict accordance with these Terms and Conditions.
            It will comply with all applicable laws and regulations (including, without limitation, all applicable laws
            regarding online conduct and acceptable content, privacy, data security, and the transmission of technical
            data
            exported from the European Union or the country in which you reside).
            The Services will not be used for illegal purposes, to publish illegal content or to promote illegal
            activities.
            The intellectual property rights of third parties will not be violated or abused.
            No confidential personal information of others will be published.
            No spam messages or unsolicited bulk messages will be sent.
            No service or network will be disrupted, interrupted or attacked.
            It will not create, distribute, or facilitate material that corresponds to malware, spyware, adware, or
            other
            malicious programs or code, or that supports or works in conjunction with such programs.
            Content posted on other websites
            We have not reviewed, and cannot review, all of the material, including computer software, made available
            through the websites and pages to which WTFAI links, and that link to WTFAI. WTFAI does not have any
            control over those non-WTFAI websites and pages, and is not responsible for their contents or their use. By
            linking to a non-WTFAI website or webpage, WTFAI does not represent or imply that it endorses such website
            or webpage. You are responsible for taking necessary security precautions to protect yourself and your
            computer
            systems from viruses, worms, Trojan horses, and other harmful and destructive content. WTFAI disclaims any
            responsibility for any harm resulting from your use of non-WTFAI websites and pages. Copyright Infringement
            and DMCA Guidelines Just as we ask others to respect our intellectual property rights, we respect the
            intellectual property rights of others. If you believe that material located on or linked to by WTFAI
            infringes your copyright, please notify us at contact@WTFAI.net. We will respond to all such notices and
            will
            remove the infringing material as required or appropriate or disable all links to the infringing material.
            We
            will terminate a visitor's access to and use of the Website if, under appropriate circumstances, the user is
            determined to be a repeat infringer of the copyright or other intellectual property rights of WTFAI or
            others.
            Changes We continually update our Services, which means that we sometimes need to change the legal terms
            under
            which our Services are offered. If we make material changes, we will notify you by posting a notice on our
            website. If you do not agree with our changes, you should discontinue your use of WTFAI within the specified
            time period. Your continued use of WTFAI will be subject to the new terms. However, any dispute that arose
            prior to the changes shall be resolved in accordance with the terms in effect at the time the dispute arose.
        </p>


        <span class="terms-of-use-sub-heading">
            Disclaimer
        </span>

        <p>
            WTFAI does not warrant that the Website will be error-free or that access will be continuous or
            uninterrupted.
            You understand that you download or obtain content or services from or through the Site at your own
            discretion
            and risk.
        </p>

        <span class="terms-of-use-sub-heading">
            Limitation of Liability
        </span>

        <p>In no event will WTFAI be liable with respect to any subject matter of this Agreement under any contract,
            negligence, strict liability or other equitable theory for: (i) any incidental, consequential or other
            damages;
            (ii) the cost of procurement for substitute products or services; (iii) interruption of use or loss or
            corruption of data. WTFAI shall have no liability for any failure or delay due to facts reasonably beyond
            its
            control. The foregoing shall not apply to the extent prohibited by applicable law.</p>

        <span class="terms-of-use-sub-heading">
            Indemnification
        </span>

        <p>
            You agree to indemnify, defend and hold harmless WTFAI and respective directors, officers, employees and
            agents from and against any and all liabilities, claims and expenses, including reasonable attorneys' fees,
            incurred by the indemnified parties in connection with any claim arising out of any breach of this
            Agreement.
        </p>


        This Terms of Service has been adapted from https://de.wordpress.com/tos/.


    </div>
</template>

<script>
    export default {
        name: "DsgvoCookieModal",
        props: ['hasConfirmed'],
        emits: ['onUpdatePrivacyPolicyConfirmState'],
        methods: {
            confirmPrivacyPolicy: function () {
                this.$emit('onUpdatePrivacyPolicyConfirmState', true);
            },
            revokePrivacyPolicy: function () {
                this.$emit('onUpdatePrivacyPolicyConfirmState', false);
            }
        }
    }
</script>

<style scoped lang="scss">

    .terms-of-use-content {
        font-family: 'Source Sans Pro', sans-serif;
        border-radius: 10px;
        background-color: rgba(44, 62, 80, 0.95);
        width: 80vw;
        min-width: 300px;
        max-width: 800px;
        padding: calc(10px + 0.5vw);
        display: flex;
        flex-direction: column;
        max-height: 55vh;
        overflow: auto;

        .terms-of-use-heading {
            font-family: bungee;
            font-size: calc(18px + 0.5vw);
            line-height: calc(18px + 0.5vw);
            margin: 15px 0;
        }

        .terms-of-use-sub-heading {
            font-size: calc(14px + 0.35vw);
            line-height: calc(14px + 0.35vw);
            font-weight: bold;
            margin: 3px 0;
        }

        p {
            text-align: justify;
        }

    }


</style>